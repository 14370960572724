import { TimePicker } from '@mui/x-date-pickers-pro';
import { useEffect, useState } from 'react';
import { Line, LinesResposne, LineStop, SortableLineStop } from '../../@types/lines';
import { useAuth, useProvideSnackBar } from '../../hooks';
import { Box } from '@mui/material';

interface LineStopTimePickerParams {
    lines: Line[];
    lineStop: LineStop;
    setLineStops: (stops: SortableLineStop[]) => void;
    sortStops: (lines: Line[]) => SortableLineStop[];
}

export default function LineStopTimePicker({ lines, lineStop, setLineStops, sortStops }: LineStopTimePickerParams) {
    const { user } = useAuth();
    const { showResponseError } = useProvideSnackBar();
    const initialTime = new Date();
    initialTime.setHours(lineStop.DepartureHour, lineStop.DepartureMinute, 0, 0);

    const [value, setValue] = useState<Date>(initialTime);

    useEffect(() => {
        const newTime = new Date();
        newTime.setHours(lineStop.DepartureHour, lineStop.DepartureMinute, 0, 0);
        setValue(newTime);
    }, [lineStop]);

    const save = async (hour: number, minute: number) => {
        const ids = lines
            .flatMap((line: Line) => line.Stops)
            .filter((stop: LineStop) => stop.Name === lineStop.Name)
            .map((stop: LineStop) => stop.ID);

        const response = await fetch('/api/linestop', {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
            body: JSON.stringify({
                Stops: ids.map((id: number) => {
                    return {
                        ID: id,
                        DepartureHour: hour,
                        DepartureMinute: minute,
                    };
                }),
            }),
        });

        if (!response.ok) {
            await showResponseError(response);
            return;
        }
        const body: LinesResposne = await response.json();
        const respLines: Line[] = body.Lines.filter((line: Line) => line.Name === lines[0].Name);
        setLineStops(sortStops(respLines));
    };

    return (
        <Box
            onKeyDown={e => {
                e.stopPropagation();
            }}
            onBlur={() => {
                if (!Number.isNaN(value.getHours())) {
                    save(value.getHours(), value.getMinutes());
                }
            }}>
            <TimePicker
                ampm={false}
                openTo='hours'
                views={['hours', 'minutes']}
                value={value}
                onChange={newValue => {
                    if (newValue) {
                        setValue(newValue);
                    }
                }}
                disableOpenPicker={true}
                slotProps={{
                    textField: {
                        variant: 'standard',
                        fullWidth: true,
                        sx: {
                            '.MuiButtonBase-root': {
                                padding: '0',
                            },
                        },
                    },
                }}
            />
        </Box>
    );
}
