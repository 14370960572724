import {
    Breadcrumbs,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Link as MuiLink,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    styled,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { DirectionType, Plan, PlanMap, PlanResponse, RequirementFilterParams, Shift, Workplace } from '../../@types/requirements';
import { formatDateToYyyyMMdd, getEnumKeyByValue, useAuth, useProvideSnackBar } from '../../utils';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import { LocalizationProvider, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import huLocale from 'date-fns/locale/hu';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ScheduleWarningModal from './ScheduleWarningModal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import SyncIcon from '@mui/icons-material/Sync';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EmailIcon from '@mui/icons-material/Email';
import SendEmailModal from './SendEmailModal';
import SyncConfirmModal from './SyncConfirmModal';
import DeleteIcon from '@mui/icons-material/Delete';
import DeletePlansModal from './DeletePlansModal';
import RouteIcon from '@mui/icons-material/Route';
import Tooltip from '@mui/material/Tooltip';
import { formatDateToYyyyMMddhhmmss, getDaysArray } from '../../utils/date-format-handler';
import TravelTime from './TravelTime';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StraightenIcon from '@mui/icons-material/Straighten';
import { RoundedFormButton } from './RoundedFormButton';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TableViewIcon from '@mui/icons-material/TableView';
import SpeedIcon from '@mui/icons-material/Speed';
import { Role } from '../../@types/auth';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TimerIcon from '@mui/icons-material/Timer';
import AutoTimeTableModal from './AutoTimeTableModal';
import UnsyncedChangesWarningModal from './UnsyncedChangesWarningModal';
import TravelDistanceWarningModal from './TravelDistanceWarningModal';

const StyledGrid = styled(Grid)`
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;
const nowAsString = new Date().toISOString().split('T')[0];

type RequirementSelectFormProps = {
    onSubmit: (params: RequirementFilterParams) => void;
    onReset: () => void;
    onFormChange: () => void;
    onPlanUpdate: (hasUnsyncedData: boolean) => void;
    setOnSynced: (isSynced: boolean) => void;
    direction: keyof typeof DirectionType;
    setDirection: (direction: keyof typeof DirectionType) => void;
    isScheduleEditing: boolean;
    setIsScheduleEditing: (isScheduleEditing: boolean) => void;
    hasUnsyncedData: boolean;
    hasNotCalculatedLineTravelDistance: boolean;
};

const DefaultWorkplace: Workplace = {
    Address: '',
    Email: '',
    Name: 'Kérem válasszon',
    QueryName: '',
    Latitude: 0,
    Longitude: 0,
    PhoneNumber: '',
    Shifts: {
        FROM_HOME: [],
        TO_HOME: [],
    },
    TravelTime: 0,
    OperationUnits: [],
};

const DefaultPlan: Plan = {
    PlanName: 'Új terv készítése',
    LastChangedAt: nowAsString,
};

const DefaultCopyPlan: Plan = {
    PlanName: 'Terv másolása',
    LastChangedAt: nowAsString,
};

const DefaultPleaseSelectPlan: Plan = {
    PlanName: 'Kérem válasszon',
    LastChangedAt: nowAsString,
};

const MenuProps = {
    PaperProps: {
        style: {
            width: 'fitContent',
        },
    },
};

const RequirementSelectForm = ({
    onSubmit,
    onReset,
    onPlanUpdate,
    onFormChange,
    setOnSynced,
    direction,
    setDirection,
    isScheduleEditing,
    setIsScheduleEditing,
    hasUnsyncedData,
    hasNotCalculatedLineTravelDistance,
}: RequirementSelectFormProps) => {
    const fromDate = new Date(formatDateToYyyyMMddhhmmss(new Date()));
    fromDate.setDate(fromDate.getDate() + (fromDate.getDay() === 0 ? 1 : 8 - fromDate.getDay()));
    const toDate = new Date(fromDate);
    toDate.setDate(fromDate.getDate() + 4);
    const initialized = useRef<boolean>(false);

    const [search, setSearchParams] = useSearchParams();

    const { showSuccess, showError, showResponseError } = useProvideSnackBar();
    const { user } = useAuth();
    const navigate = useNavigate();

    const [isPlanSyncLoading, setIsPlanSyncLoading] = useState(false);
    const [isCreatePlanLoading, setIsCreatePlanLoading] = useState(false);
    const [isCopyPlanLoading, setIsCopyPlanLoading] = useState(false);
    const [isTravelDistanceCalculationLoading, setIsTravelDistanceCalculationLoading] = useState(false);
    const [isReverseCopyLoading, setIsReverseCopyLoading] = useState(false);
    const [isSyncPassengersLoading, setIsSyncPassengersLoading] = useState(false);
    const [workplaceList, setWorkplaceList] = useState<Array<Workplace>>([DefaultWorkplace]);

    const [from, setFrom] = useState<Date>(fromDate);
    const [to, setTo] = useState<Date>(toDate);

    const [workplace, setWorkplace] = useState<Workplace>(DefaultWorkplace);
    const [selectedOperationUnits, setSelectedOperationUnits] = useState<string[]>([]);

    const [plan, setPlan] = useState<Plan>(DefaultPlan);
    const [checkboxDates, setCheckboxDates] = useState<Date[]>([]);
    const [requirementDates, setRequirementDates] = useState<Date[]>([]);
    const [planShiftNames, setPlanShiftNames] = useState<string[]>([]);
    const [selectedShifts, setSelectedShifts] = useState<Shift[]>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [designStarted, setDesignStarted] = useState<boolean>(false);
    const [plans, setPlans] = useState<PlanMap>({});
    const [planNameSuffix, setPlanNameSuffix] = useState<string>('');
    const [syncModalOpen, setSyncModalOpen] = useState<boolean>(false);
    const [copyMode, setCopyMode] = useState<boolean>(false);
    const [copyPlan, setCopyPlan] = useState<Plan>(DefaultPleaseSelectPlan);
    const [emailModalOpen, setEmailModalOpen] = useState<boolean>(false);
    const [deletePlansModalOpen, setDeletePlansModalOpen] = useState<boolean>(false);
    const [autoTimeTableModalOpen, setAutoTimeTableModalOpen] = useState<boolean>(false);
    const [unsyncedChangesWarningModalOpen, setUnsyncedChangesWarningModalOpen] = useState<boolean>(false);
    const [modalNavigation, setModalNavigation] = useState<string>('/planner');
    const [mobileAppSyncAt, setMobileAppSyncAt] = useState<Date | null>(null);
    const [emailNotificationsSentAt, setEmailNotificationsSentAt] = useState<Date | null>(null);
    const [travelDistanceWarningModalOpen, setTravelDistanceWarningModalOpen] = useState<boolean>(false);

    const fetchPlans = async () => {
        const response = await fetch('/api/plans', {
            headers: {
                Authorization: `Bearer ${user?.accessToken}`,
            },
        });

        if (response.status === 200) {
            const existingPlans: PlanMap = await response.json();
            setPlans(existingPlans);
            return;
        }
        await showResponseError(response);
    };

    const fetchPlan = async (id: number, workplaces: Workplace[]) => {
        const response = await fetch(`/api/plans/${id}`, {
            headers: {
                Authorization: `Bearer ${user?.accessToken}`,
            },
        });

        if (response.status === 200) {
            const planResponse: PlanResponse = await response.json();
            const planWorkplace = workplaces.find(w => w.Name === planResponse.Workplace) || DefaultWorkplace;

            const fromDate = new Date(formatDateToYyyyMMddhhmmss(new Date(planResponse.StartAt)));
            setFrom(fromDate);
            const toDate = new Date(formatDateToYyyyMMddhhmmss(new Date(planResponse.EndAt)));
            setTo(toDate);

            setWorkplaceWithShiftUpdate(planWorkplace);
            setDirection(planResponse.Direction === 'FROM_HOME' ? 'FROM_HOME' : 'TO_HOME');

            const planShiftNames = planResponse.Requirements[0].Shifts.map(s => s.Name.toLowerCase());
            setPlanShiftNames(planShiftNames);
            setSelectedShifts(selectedShiftParser(planWorkplace));

            const planOperationUnits = planResponse.Requirements[0].OperationUnits.map(s => s.Name);
            setSelectedOperationUnits(planOperationUnits);
            if (planOperationUnits.length === 0) {
                setSelectedOperationUnits(planWorkplace.OperationUnits);
            }
            const planNameSuffix = planResponse.NameSuffix || '';
            setPlanNameSuffix(planNameSuffix);

            const dates: Date[] = [];
            planResponse.Requirements.forEach(r => {
                dates.push(new Date(r.DueAt));
            });
            setRequirementDates(dates);

            const planList = [DefaultPlan];

            planList.push({
                PlanID: planResponse.ID,
                PlanName: planResponse.Name,
                StartAt: planResponse.StartAt,
                EndAt: planResponse.EndAt,
                TravelTime: planResponse.TravelTime,
                ReverseDirectionPlanID: planResponse.ReverseDirectionPlanID,
                LastChangedAt: planResponse.LastChangedAt,
            });

            setPlans({
                [planResponse.Workplace]: planList,
            });

            if (planWorkplace.QueryName) {
                setPlan({
                    PlanID: planResponse.ID,
                    PlanName: planResponse.Name,
                    StartAt: planResponse.StartAt,
                    EndAt: planResponse.EndAt,
                    MobileAppSyncedAt: planResponse.MobileAppSyncedAt || null,
                    EmailNotificationsSentAt: planResponse.EmailNotificationsSentAt || null,
                    LastChangedAt: planResponse.LastChangedAt,
                    TravelTime: planResponse.TravelTime,
                    ReverseDirectionPlanID: planResponse.ReverseDirectionPlanID,
                });
                setOnSynced(planResponse.MobileAppSyncedAt !== null || planResponse.EmailNotificationsSentAt !== null);
                if (planResponse.EmailNotificationsSentAt) {
                    const emailNotificationsSentAt = new Date(Date.parse(planResponse.EmailNotificationsSentAt || ''));
                    setEmailNotificationsSentAt(emailNotificationsSentAt);
                }

                if (planResponse.MobileAppSyncedAt) {
                    const mobilAppSyncedAt = Date.parse(planResponse.MobileAppSyncedAt || '');
                    const lastChangedAt = Date.parse(planResponse.LastChangedAt || '');
                    setMobileAppSyncAt(new Date(mobilAppSyncedAt));

                    onPlanUpdate(lastChangedAt > mobilAppSyncedAt);
                }
            }

            if (workplace.QueryName) {
                const params: RequirementFilterParams = {
                    workplace,
                    shifts: selectedShifts.map(s => s.Name),
                    direction,
                    from,
                    to,
                    planID: planResponse.ID.toString(),
                };
                onSubmit(params);
                setDesignStarted(true);
            }
            return Promise.resolve(planResponse);
        }

        await showResponseError(response);
    };

    const fetchWorkplaces = async (): Promise<Workplace[] | undefined> => {
        const dates = checkboxDates.length ? checkboxDates : getAllDatesInRange(from, to, requirementDates);
        const response = await fetch(
            '/api/workplaces?' +
                new URLSearchParams({
                    days: dates.map(d => formatDateToYyyyMMdd(d)).join(','),
                }).toString(),
            {
                headers: {
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            }
        );

        if (response.status === 200) {
            const workplaces: Workplace[] = [DefaultWorkplace, ...(await response.json())];
            return Promise.resolve(workplaces);
        }

        await showResponseError(response);
    };

    const isCompatibleShift = (shiftToCheck: Shift, selectedShifts: Shift[]): boolean => {
        if (selectedShifts.length === 0) {
            return true;
        }

        const AcceptableMinutes = 30;

        const minutesDifference = (time1: string, time2: string) => {
            const [hours1, minutes1] = time1.split(':').map(Number);
            const [hours2, minutes2] = time2.split(':').map(Number);

            return Math.abs(hours1 * 60 + minutes1 - (hours2 * 60 + minutes2));
        };

        if (isScheduleEditing) {
            return true;
        }

        for (const shift of selectedShifts) {
            const selectedShiftsTime = direction === getEnumKeyByValue(DirectionType, DirectionType.FROM_HOME) ? shift.ArrivalAt : shift.DepartureAt;
            const checkedShiftsTime =
                direction === getEnumKeyByValue(DirectionType, DirectionType.FROM_HOME) ? shiftToCheck.ArrivalAt : shiftToCheck.DepartureAt;

            if (
                direction === getEnumKeyByValue(DirectionType, DirectionType.FROM_HOME) &&
                minutesDifference(selectedShiftsTime, checkedShiftsTime) > AcceptableMinutes
            ) {
                return false;
            }
        }
        return true;
    };

    const validateGeneralRequirements = () => {
        if (!direction || isCreatePlanLoading) {
            return false;
        }
        if (!workplace.QueryName) {
            showError('Kérem válasszon munkahelyet!');
            return false;
        }
        if (!from || !to) {
            showError('Kérem válasszon tervezendő időszakot!');
            return false;
        }
        if (selectedShifts.length === 0) {
            showError('Kérem válasszon legalább egy műszakot!');
            return false;
        }
        if (selectedOperationUnits.length === 0) {
            showError('Kérem válasszon legalább egy részleget!');
            return false;
        }
        if (direction === 'TO_HOME' && !planNameSuffix.trim()) {
            showError('Hazaszállítás esetén a terv név kiegészítés megadása kötelező!');
            return false;
        }
        return true;
    };

    const getMinMaxSelectedShiftDates = () => {
        let arrivalAt = null;
        let departureAt = null;

        if (selectedShifts.length > 0) {
            arrivalAt = selectedShifts.sort((a, b) => (a.ArrivalAt < b.ArrivalAt ? 1 : -1))[0].ArrivalAt;
            departureAt = selectedShifts.sort((a, b) => (a.DepartureAt > b.DepartureAt ? 1 : -1))[0].DepartureAt;
        }

        return { arrivalAt, departureAt };
    };

    const createRequirement = async () => {
        if (!validateGeneralRequirements()) {
            return;
        }
        setIsCreatePlanLoading(true);

        const { arrivalAt, departureAt } = getMinMaxSelectedShiftDates();

        const response = await fetch('/api/requirements', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
            body: JSON.stringify({
                Workplace: workplace?.Name,
                Shifts: selectedShifts.map(s => s.Name + (s.Name.includes('rendkívüli') ? '-' + s.StartAt : '')),
                Direction: direction,
                DueDays: checkboxDates.map(v => formatDateToYyyyMMdd(v)),
                ShiftArrivalAt: arrivalAt ? arrivalAt : '',
                ShiftDepartureAt: departureAt ? departureAt : '',
                OperationUnits: selectedOperationUnits.length !== workplace.OperationUnits.length ? selectedOperationUnits : [],
                PlanNameSuffix: planNameSuffix,
                TravelTime: 90,
            }),
        });

        if (!response.ok) {
            const error = await response.json();
            setIsCreatePlanLoading(false);
            showError(error.error);
            return;
        }

        const respData = await response.json();
        setIsCreatePlanLoading(false);

        const params: RequirementFilterParams = {
            workplace,
            shifts: selectedShifts.map(s => s.Name),
            direction,
            from,
            to,
            planID: respData.PlanID,
        };

        onSubmit(params);
        navigate(`/planner?planID=${respData.PlanID}`);
    };

    const fetchReverseCopy = async () => {
        try {
            setIsReverseCopyLoading(true);
            const response = await fetch('/api/plans/copy_home_direction', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    SourcePlanID: parseInt(search.get('planID') || ''),
                }),
            });

            if (!response.ok) {
                showResponseError(response);
                return;
            }

            const respData = await response.json();

            navigate(`/planner?planID=${respData.PlanID}`);
            const params: RequirementFilterParams = {
                workplace,
                shifts: selectedShifts.map(s => s.Name),
                direction: respData.Direction,
                from,
                to,
                planID: respData.PlanID,
            };
            setDirection(respData.Direction);
            onSubmit(params);
        } catch (error: any) {
            showError(`Hiba történt a visszaút létrehozása közben: ${error.message}`);
        } finally {
            setIsReverseCopyLoading(false);
        }
    };

    const fetchSyncPassengers = async () => {
        if (isSyncPassengersLoading) return;
        try {
            setIsSyncPassengersLoading(true);
            const response = await fetch('/api/plans/sync_passengers', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    PlanID: plan.PlanID,
                }),
            });
            if (response.ok && plan.PlanID !== undefined) {
                const params: RequirementFilterParams = {
                    workplace,
                    shifts: selectedShifts.map(s => s.Name),
                    direction,
                    from,
                    to,
                    planID: plan.PlanID.toString(),
                };
                onSubmit(params);
            }
            showResponseError(response);
            setIsSyncPassengersLoading(false);
        } catch (error: any) {
            showError('Hiba történt az adatok szinkronizálása közben');
        }
    };

    const datesBetween = (startDate: Date, endDate: Date, selectedDates: Date[]): Date[] => {
        const dates: Date[] = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            if (selectedDates.some(d => formatDateToYyyyMMdd(d) === formatDateToYyyyMMdd(currentDate))) {
                dates.push(new Date(currentDate));
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    };

    const fetchCopyPlan = async () => {
        if (isCopyPlanLoading) return;

        if (!copyPlan.PlanID) {
            showError(`Kérem válasszon másolandó tervet!`);
            return;
        }

        if (copyPlan.PlanName.toLowerCase().includes('haza') && !planNameSuffix.trim()) {
            showError('Hazaszállítás esetén a terv név kiegészítés megadása kötelező!');
            return;
        }

        try {
            setIsCopyPlanLoading(true);
            const dueDays = datesBetween(from, to, checkboxDates).map(date => formatDateToYyyyMMdd(date));

            const response = await fetch('/api/plans/copy', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    SourcePlanID: copyPlan.PlanID,
                    DueDays: dueDays,
                    PlanNameSuffix: planNameSuffix,
                }),
            });

            if (!response.ok) {
                setIsCopyPlanLoading(false);
                showResponseError(response);
                return;
            }

            const responseData = await response.json();
            const newPlanID = responseData.PlanID;

            navigate(`/planner?planID=${newPlanID}`);
            const params: RequirementFilterParams = {
                workplace,
                shifts: selectedShifts.map(s => s.Name),
                direction,
                from,
                to,
                planID: newPlanID,
            };
            onSubmit(params);
            setDesignStarted(true);
            setCopyMode(false);
            setIsCopyPlanLoading(false);
            showSuccess('Sikeres terv másolás');
        } catch (error: any) {
            showError(`Hiba történt a menetrend másolás adat elküldése közben: ${error.message}`);
        }
    };

    const fetchUpdateSchedule = async (id: number | undefined) => {
        if (!id) {
            showError(`Kérem válasszon tervet!`);
            return;
        }
        try {
            const { arrivalAt, departureAt } = getMinMaxSelectedShiftDates();

            const updateScheduleData = {
                NameSuffix: planNameSuffix,
                DueDays: checkboxDates.map(v => formatDateToYyyyMMdd(v)),
                Shifts: selectedShifts.map(s => s.Name + (s.Name.includes('rendkívüli') ? '-' + s.StartAt : '')),
                OperationUnit: selectedOperationUnits.length !== workplace.OperationUnits.length ? selectedOperationUnits : [],
                DepartureAt: departureAt,
                ArrivalAt: arrivalAt,
            };
            const response = await fetch(`/api/plans/${id}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify(updateScheduleData),
            });
            if (response.ok && plan.PlanID !== undefined) {
                const planResponse: PlanResponse = await response.json();
                setPlan({
                    PlanID: planResponse.ID,
                    PlanName: planResponse.Name,
                    StartAt: planResponse.StartAt,
                    EndAt: planResponse.EndAt,
                    MobileAppSyncedAt: planResponse.MobileAppSyncedAt || null,
                    EmailNotificationsSentAt: planResponse.EmailNotificationsSentAt || null,
                    LastChangedAt: planResponse.LastChangedAt,
                    TravelTime: planResponse.TravelTime,
                    ReverseDirectionPlanID: planResponse.ReverseDirectionPlanID,
                });
                const params: RequirementFilterParams = {
                    workplace,
                    shifts: selectedShifts.map(s => s.Name),
                    direction,
                    from,
                    to,
                    planID: plan.PlanID.toString(),
                };

                onSubmit(params);
                showSuccess('Sikeres menetrend frissítés');
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError(`Hiba történt a menetrend frissítése közben: ${error.message}`);
        }
    };

    const getAllDatesInRange = (from: Date, to: Date, requirementDates: Date[]) => {
        const currentDate = new Date(from);
        const dates = [];
        while (currentDate <= to) {
            if (requirementDates.length === 0 || requirementDates.some(d => formatDateToYyyyMMdd(d) === formatDateToYyyyMMdd(currentDate))) {
                dates.push(new Date(currentDate));
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    const setWorkplaceWithShiftUpdate = ((newWorkplace: Workplace) => {
        setWorkplace((prev)=> {
            if(prev.Name !== newWorkplace.Name) {
                setSelectedShifts([]);
            }
            return newWorkplace;
        })
    })

    useEffect(() => {
        const onUnload = (e: BeforeUnloadEvent) => {
            if (hasUnsyncedData) {
                e.preventDefault();
            }
        };

        window.addEventListener('beforeunload', onUnload);

        return () => window.removeEventListener('beforeunload', onUnload);
    }, [hasUnsyncedData]);

    useEffect(() => {
        if (plan.PlanID && initialized.current && !designStarted && plans) {
            fetchPlan(plan.PlanID, workplaceList);
        }
    }, [plan]);

    useEffect(() => {
        fetchPlans();
    }, [search]);

    useEffect(() => {
        const init = async () => {
            const id = Number(search.get('planID'));

            if (id === 0 || isNaN(id)) {
                initialized.current = true;
                return;
            }
            const w = await fetchWorkplaces();

            if (!w) {
                return;
            }

            await fetchPlan(id, w);
            setWorkplaceList(w);

            initialized.current = true;
        };
        init();
    }, [search]);

    useEffect(() => {
        if (!from || !to || !workplace.QueryName || !initialized.current) {
            return;
        }
        fetchPlans();
    }, [from, to, workplace]);

    useEffect(() => {
        if (!workplace.QueryName || !from || !to || !direction || selectedShifts.length === 0 || !initialized.current) {
            return;
        }

        const params: RequirementFilterParams = {
            workplace,
            shifts: selectedShifts.map(s => s.Name),
            direction,
            from,
            to,
            planID: search.get('planID'),
        };
        onSubmit(params);
    }, [workplace, from, to, direction, selectedShifts]);

    useEffect(() => {
        if (!initialized.current && checkboxDates.length > 0) {
            return;
        }

        const allDates = getAllDatesInRange(from, to, requirementDates);
        setCheckboxDates(allDates);
    }, [from, to, requirementDates]);

    useEffect(() => {
        if (!initialized.current) {
            return;
        }
        fetchWorkplaces().then(wplace => {
            if (!wplace) return;
            setWorkplaceList(wplace);
            const previousWorkplace = wplace.find(w => w.Name === workplace.Name);
            const wp = previousWorkplace || DefaultWorkplace;
            setWorkplaceWithShiftUpdate(wp);
            setSelectedShifts(selectedShiftParser(wp));
            if (!previousWorkplace) {
                setPlan(DefaultPlan);
                setSelectedShifts([]);
            }
        });
    }, [checkboxDates]);

    useEffect(() => {
        setSelectedShifts([]);
    }, [direction]);

    const isDisabled = (): boolean => {
        return isCreatePlanLoading || isCopyPlanLoading || isReverseCopyLoading || isTravelDistanceCalculationLoading || designStarted || copyMode;
    };

    const getButtonText = (): string => {
        if (copyMode) {
            return 'Terv másolása';
        } else if (designStarted) {
            return 'Új terv';
        } else {
            return 'Tervezés';
        }
    };

    const getButtonIcon = (): JSX.Element => {
        if (isCreatePlanLoading || isCopyPlanLoading) {
            return <CircularProgress size={22} />;
        }

        if (copyMode) {
            return <ContentCopyIcon />;
        }

        if (designStarted) {
            return <RouteIcon />;
        }

        return <EditRoadIcon />;
    };

    const getUniqueShiftValue = (s: Shift) => {
        return `${s.Name}||${s.StartAt}||${s.ArrivalAt}`;
    };

    const handleDateRangeChange = (newValue: [Date | null, Date | null]) => {
        if (newValue[0] && newValue[1]) {
            setFrom(newValue[0]);
            setTo(newValue[1]);
            setCopyMode(false);
        }
    };

    const isDateRangeError = (): boolean => {
        if (from && to) {
            const max7Days = (to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24);
            return max7Days > 6;
        }
        return false;
    };

    const handleDeletePlan = () => {
        setDeletePlansModalOpen(false);
        setWorkplaceWithShiftUpdate(DefaultWorkplace);
        setPlan(DefaultPlan);
        setSelectedShifts([]);
        setDirection(getEnumKeyByValue(DirectionType, DirectionType.FROM_HOME) as keyof typeof DirectionType);
        setDesignStarted(false);
        setPlanNameSuffix('');
        setFrom(fromDate);
        setTo(toDate);
        setRequirementDates([]);
        const allDates = getAllDatesInRange(fromDate, toDate, []);
        setCheckboxDates(allDates);
        const params: RequirementFilterParams = {
            workplace,
            shifts: selectedShifts.map(s => s.Name),
            direction,
            from,
            to,
            planID: null,
        };
        onSubmit(params);
        onReset();
        navigate('/planner');
    };

    const handleOperationUnitChange = (event: SelectChangeEvent<typeof selectedOperationUnits>) => {
        const {
            target: { value },
        } = event;
        const units = typeof value === 'string' ? value.split(',') : value;
        if (units.includes('Minden kijelölése')) {
            setSelectedOperationUnits(workplace.OperationUnits);
            return;
        }
        if (units.includes('Kijelölések megszűntetése')) {
            setSelectedOperationUnits([]);
            return;
        }

        setSelectedOperationUnits(units.filter(u => u !== ('Kérem válasszon' || 'Mindent kijelölése' || 'Kijelölések megszűntetése')));
        setCopyMode(false);
    };

    const handleShiftChange = (event: SelectChangeEvent<string[]>, child: React.ReactNode) => {
        const selectedShiftNames = event.target.value as string[];
        const shifts = workplace.Shifts[direction]?.filter(shift => selectedShiftNames.includes(getUniqueShiftValue(shift)));
        setSelectedShifts(shifts);
        setCopyMode(false);
    };

    const updateTravelDistance = async () => {
        if (isTravelDistanceCalculationLoading) return;
        setIsTravelDistanceCalculationLoading(true);
        const response = await fetch('/api/plans/update_travel_distance', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
            body: JSON.stringify({
                PlanID: plan.PlanID,
            }),
        });

        if (!response.ok) {
            setIsTravelDistanceCalculationLoading(false);
            showResponseError(response);
            return;
        }

        showSuccess('Sikeres megtett távolság frissítés');
        setIsTravelDistanceCalculationLoading(false);
        onPlanUpdate(true);
        onFormChange();
    };

    const selectedShiftParser = (workplace: Workplace): Shift[] => {
        return planShiftNames
            .map(s => {
                if (s.includes('rendkívüli')) {
                    const splitted = s.split('-');
                    const name = splitted.slice(0, splitted.length - 1).join('-');
                    const startAt = splitted[splitted.length - 1];
                    return workplace.Shifts[direction]?.find(shift => shift.Name === name && shift.StartAt === startAt);
                }
                return workplace.Shifts[direction]?.find(shift => shift.Name === s);
            })
            .filter(shift => shift !== undefined) as Shift[];
    };

    return (
        <Grid container px={2}>
            <Grid container px={1} py={1} spacing={1}>
                <Grid container py={0.5} textAlign={'right'} sx={{ justifyContent: 'space-between' }}>
                    <Breadcrumbs aria-label='breadcrumb' sx={{ display: 'inline-block' }}>
                        <Grid item>{plan.PlanName}</Grid>
                        {plan.ReverseDirectionPlanID && (
                            <Grid item>
                                <>
                                    {direction === 'FROM_HOME' && (
                                        <Link
                                            to={`/planner?planID=${plan.ReverseDirectionPlanID}`}
                                            onClick={event => {
                                                if (hasUnsyncedData) {
                                                    setModalNavigation(`/planner?planID=${plan.ReverseDirectionPlanID}`);
                                                    setUnsyncedChangesWarningModalOpen(true);
                                                    event.preventDefault();
                                                }
                                            }}>
                                            Kapcsolódó hazaszállítás menetrendje
                                        </Link>
                                    )}
                                    {direction === 'TO_HOME' && (
                                        <Link to={`/planner?planID=${plan.ReverseDirectionPlanID}`}>Kapcsolódó munkahelyre szállítás menetrendje</Link>
                                    )}
                                </>
                            </Grid>
                        )}
                    </Breadcrumbs>
                    <Breadcrumbs aria-label='breadcrumb' sx={{ display: 'inline-block' }}>
                        <MuiLink
                            component={Link}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}
                            color='inherit'
                            to='/plans'
                            onClick={event => {
                                if (hasUnsyncedData) {
                                    event.preventDefault();
                                    setModalNavigation('/plans');
                                    setUnsyncedChangesWarningModalOpen(true);
                                }
                            }}>
                            Tervek
                            <ListAltIcon sx={{ ml: 0.5, mb: -0.125 }} fontSize={'small'} />
                        </MuiLink>
                        {(user?.hasRole(Role.FOVEZENYLO) || user?.hasRole(Role.ADMIN)) && (
                            <MuiLink
                                component={Link}
                                underline='hover'
                                sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}
                                color='inherit'
                                to='/speed-limits'
                                onClick={event => {
                                    if (hasUnsyncedData) {
                                        event.preventDefault();
                                        setModalNavigation('/speed-limits');
                                        setUnsyncedChangesWarningModalOpen(true);
                                    }
                                }}>
                                Sebességlimitek
                                <SpeedIcon sx={{ ml: 0.5, mb: -0.125 }} fontSize={'small'} />
                            </MuiLink>
                        )}
                        <MuiLink
                            component={Link}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}
                            color='inherit'
                            to='/matrix'
                            onClick={event => {
                                if (hasUnsyncedData) {
                                    event.preventDefault();
                                    setModalNavigation('/matrix');
                                    setUnsyncedChangesWarningModalOpen(true);
                                }
                            }}>
                            Távolságmátrix
                            <TableViewIcon sx={{ ml: 0.5, mb: -0.125 }} fontSize={'small'} />
                        </MuiLink>

                        <MuiLink
                            component={Link}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}
                            color='inherit'
                            to='/schedule'
                            onClick={event => {
                                if (hasUnsyncedData) {
                                    event.preventDefault();
                                    setModalNavigation('/schedule');
                                    setUnsyncedChangesWarningModalOpen(true);
                                }
                            }}>
                            Menetrend
                            <VisibilityIcon sx={{ ml: 0.5, mb: -0.125 }} fontSize={'small'} />
                        </MuiLink>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <StyledGrid container p={1} spacing={1.25} sx={{ alignItems: 'start' }}>
                <Grid item xs={12} sm={8} md={'auto'}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={huLocale}>
                        <DateRangePicker
                            label={'Tervezendő időszak'}
                            sx={{ width: '14rem' }}
                            value={[from, to]}
                            onChange={handleDateRangeChange}
                            disabled={isDisabled()}
                            slots={{ field: SingleInputDateRangeField }}
                            slotProps={{
                                textField: {
                                    variant: 'standard',
                                    fullWidth: true,
                                    error: isDateRangeError(),
                                    helperText: isDateRangeError() ? 'Maximum 7 napot lehet kiválasztani' : '',
                                },
                            }}
                            calendars={1}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item>
                    <Grid container spacing={0}>
                        {getDaysArray(from, to).map(date => (
                            <Grid item key={date.getTime()} p={0} m={0}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkboxDates.find(d => d.getTime() === date.getTime()) !== undefined}
                                            onChange={checkevent => {
                                                if (checkevent.target.checked) {
                                                    setCheckboxDates([...checkboxDates, date]);
                                                } else {
                                                    setCheckboxDates([...checkboxDates.filter(d => d.getTime() !== date.getTime())]);
                                                }
                                                setCopyMode(false);
                                            }}
                                            disabled={isTravelDistanceCalculationLoading || (designStarted && !isScheduleEditing)}
                                            sx={{ padding: 0.25, paddingTop: 0 }}
                                        />
                                    }
                                    label={date.toLocaleDateString('hu-HU', {
                                        weekday: 'short',
                                    })}
                                    labelPlacement='top'
                                    sx={{ padding: 0, margin: 0 }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={4} md={'auto'} justifyContent={'start'}>
                    <FormControl sx={{ width: '12rem' }}>
                        <InputLabel id='workplace-label' variant='standard'>
                            Partner
                        </InputLabel>
                        <Select
                            variant='standard'
                            labelId='workplace-label'
                            value={workplace?.Name}
                            disabled={isDisabled()}
                            label='Partner'
                            onChange={e => {
                                const w = workplaceList.find(w => w.Name === e.target.value);
                                setWorkplaceWithShiftUpdate(w || DefaultWorkplace);
                                setSelectedOperationUnits(w?.OperationUnits || []);
                                setCopyMode(false);
                            }}>
                            {workplaceList.map(item => (
                                <MenuItem key={item.Name} value={item.Name}>
                                    {item.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} md={'auto'} justifyContent={'start'}>
                    <FormControl sx={{ width: '12rem' }}>
                        <InputLabel id='workplace-label' variant='standard'>
                            Tervek
                        </InputLabel>
                        <Select
                            variant='standard'
                            value={plan.PlanName}
                            label='Partner'
                            disabled={isTravelDistanceCalculationLoading || designStarted}
                            onChange={e => {
                                const selectedValue = e.target.value;
                                if (selectedValue === DefaultCopyPlan.PlanName) {
                                    setCopyMode(true);
                                    setPlan(DefaultCopyPlan);
                                    return;
                                }
                                setCopyMode(false);
                                const selectedPlan = plans[workplace.Name].find(p => p.PlanName === e.target.value);
                                setPlan(selectedPlan || DefaultPlan);
                                selectedPlan && selectedPlan.PlanID ? search.set('planID', selectedPlan.PlanID.toString()) : search.delete('planID');
                                setSearchParams(search);
                            }}
                            title={plan.PlanName}
                            sx={{ maxWidth: '200px' }}>
                            <MenuItem key={DefaultPlan.PlanName} value={DefaultPlan.PlanName}>
                                {DefaultPlan.PlanName}
                            </MenuItem>
                            {plans[workplace.Name]?.length > 0 && (user?.hasRole(Role.FOVEZENYLO) || user?.hasRole(Role.ADMIN)) && (
                                <MenuItem value={DefaultCopyPlan.PlanName}>{DefaultCopyPlan.PlanName}</MenuItem>
                            )}
                            {(plans[workplace.Name] || []).map(p => (
                                <MenuItem key={p.PlanName} value={p.PlanName}>
                                    {p.PlanName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {copyMode ? (
                    <>
                        <Grid item xs={12} sm={4} md={'auto'} justifyContent={'start'}>
                            <FormControl sx={{ minWidth: '12rem' }}>
                                <InputLabel id='plan-label' variant='standard'>
                                    Másolandó terv
                                </InputLabel>
                                <Select
                                    variant='standard'
                                    value={copyPlan.PlanName || ''}
                                    label='Másolandó terv'
                                    onChange={e => {
                                        const selectedValue = e.target.value;
                                        const selectedPlan = plans[workplace.Name].find(p => p.PlanName === selectedValue) || DefaultPleaseSelectPlan;

                                        setCopyPlan(selectedPlan);
                                        setCopyMode(true);
                                    }}
                                    title={copyPlan.PlanName}
                                    sx={{ maxWidth: '200px' }}>
                                    {[DefaultPleaseSelectPlan, ...plans[workplace.Name]].map(p => (
                                        <MenuItem key={p.PlanName} value={p.PlanName}>
                                            {p.PlanName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={'auto'}>
                            <TextField
                                label='Terv név kiegészítés'
                                variant='standard'
                                value={planNameSuffix}
                                onChange={e => setPlanNameSuffix(e.target.value)}
                                required={direction === 'TO_HOME'}
                                fullWidth
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} sm={4} md={'auto'}>
                            <FormControl sx={{ width: '7rem' }}>
                                <InputLabel id='workplace-direction' variant='standard'>
                                    Irány
                                </InputLabel>
                                <Select
                                    labelId='workplace-direction'
                                    value={direction}
                                    disabled={isDisabled()}
                                    label='Irány'
                                    onChange={e => {
                                        setDirection(e.target.value as keyof typeof DirectionType);
                                        setCopyMode(false);
                                    }}
                                    variant='standard'>
                                    <MenuItem value={getEnumKeyByValue(DirectionType, DirectionType.FROM_HOME)}>{DirectionType.FROM_HOME}</MenuItem>
                                    <MenuItem value={getEnumKeyByValue(DirectionType, DirectionType.TO_HOME)}>{DirectionType.TO_HOME}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4} md={'auto'}>
                            <FormControl sx={{ width: '12rem' }}>
                                <InputLabel id='operation-units-label' variant='standard'>
                                    Üzemegységek
                                </InputLabel>
                                <Select
                                    labelId='operation-units-label'
                                    id='operation-units'
                                    variant='standard'
                                    multiple
                                    disabled={isDisabled() && !isScheduleEditing}
                                    value={selectedOperationUnits.length ? selectedOperationUnits : ['Kérem válasszon']}
                                    onChange={handleOperationUnitChange}
                                    MenuProps={MenuProps}
                                    renderValue={(selected: string[] | string) => (typeof selected === 'string' ? selected : selected.join(', '))}
                                    fullWidth>
                                    {workplace.OperationUnits.length === 0 && (
                                        <MenuItem value=''>
                                            <ListItemText primary={'Nincsenek részlegek'} />
                                        </MenuItem>
                                    )}
                                    {workplace.OperationUnits.length > 0 && (
                                        <MenuItem value='Minden kijelölése' sx={{ p: 0, paddingRight: 2 }}>
                                            <Checkbox size={'small'} checked={selectedOperationUnits.length === workplace.OperationUnits.length} />
                                            <small>Minden kijelölése</small>
                                        </MenuItem>
                                    )}
                                    {workplace.OperationUnits.length > 0 && (
                                        <MenuItem value='Kijelölések megszűntetése' sx={{ p: 0, paddingRight: 2 }}>
                                            <Checkbox size={'small'} checked={selectedOperationUnits.length === 0} />
                                            <small>Kijelölések megszűntetése</small>
                                        </MenuItem>
                                    )}
                                    {workplace.OperationUnits.map(unit => (
                                        <MenuItem key={unit} value={unit} sx={{ p: 0, paddingRight: 2 }}>
                                            <Checkbox size={'small'} checked={selectedOperationUnits.includes(unit)} />
                                            <small>{unit}</small>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4} md={'auto'}>
                            <FormControl sx={{ width: '12rem' }}>
                                <InputLabel id='workplace-shift-label' sx={{ top: '8px', left: '-14px' }}>
                                    Műszak
                                </InputLabel>
                                <Select
                                    labelId='workplace-shift-label'
                                    id='workplace-shift'
                                    variant='standard'
                                    disabled={designStarted && !isScheduleEditing}
                                    displayEmpty
                                    multiple
                                    value={selectedShifts.length ? selectedShifts.map(s => getUniqueShiftValue(s)) : ['Kérem válasszon']}
                                    onChange={handleShiftChange}
                                    placeholder={'kérem válasszon'}
                                    renderValue={(selected: string[]) => selected.map(s => s.split('||')[0]).join(', ')}
                                    fullWidth>
                                    {workplace.Shifts[direction]?.length ? (
                                        workplace.Shifts[direction]
                                            .filter(s => s.StartAt)
                                            .filter(s =>
                                                designStarted && !isScheduleEditing ? selectedShifts.find(selected => selected.Name === s.Name) : true
                                            )
                                            .map(s => {
                                                const compatible = isCompatibleShift(s, selectedShifts);
                                                return (
                                                    <MenuItem
                                                        key={s.Name + s.StartAt + s.CountOfWorkers}
                                                        value={getUniqueShiftValue(s)}
                                                        sx={{ p: 0, paddingRight: 2 }}
                                                        disabled={!compatible || (isDisabled() && !isScheduleEditing)}>
                                                        <Checkbox
                                                            size={'small'}
                                                            checked={selectedShifts.some(shift => {
                                                                return shift.Name === s.Name && shift.StartAt === s.StartAt && shift.ArrivalAt === s.ArrivalAt;
                                                            })}
                                                        />
                                                        <small>
                                                            {s.Name + ' ' + (s.StartAt.trim() ? '(' + s.StartAt + ')' : '') + ` (${s.CountOfWorkers} fő)`}
                                                        </small>
                                                    </MenuItem>
                                                );
                                            })
                                    ) : (
                                        <MenuItem value=''>
                                            <ListItemText primary={`Nincs beküldött beosztás`} />
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        {designStarted && !isScheduleEditing ? null : (
                            <Grid item xs={12} sm={4} md={'auto'}>
                                <TextField
                                    label='Terv név kiegészítés'
                                    variant='standard'
                                    value={planNameSuffix}
                                    onChange={e => setPlanNameSuffix(e.target.value)}
                                    required={direction === 'TO_HOME'}
                                    fullWidth
                                />
                            </Grid>
                        )}
                        {!!plan.TravelTime && !isScheduleEditing && (
                            <Grid item xs={12} sm={4} md={'auto'}>
                                <TravelTime
                                    initialTravelTime={plan.TravelTime || workplace.TravelTime}
                                    planID={plan.PlanID}
                                    onUpdate={() => onPlanUpdate(true)}
                                />
                            </Grid>
                        )}
                    </>
                )}

                <Grid item xs={12} sm={4} md={'auto'} p={0} alignItems={'end'} ml={'auto'}>
                    <Grid container spacing={0.25}>
                        <Grid item>
                            <ScheduleWarningModal open={modalOpen} onClose={() => setModalOpen(false)} />
                        </Grid>

                        <Grid item>
                            <AutoTimeTableModal
                                onUpdate={() => {
                                    onPlanUpdate(true);
                                    onFormChange();
                                }}
                                open={autoTimeTableModalOpen}
                                onClose={() => setAutoTimeTableModalOpen(false)}
                                plan={plan}
                            />
                        </Grid>

                        <Grid item>
                            <SyncConfirmModal
                                open={syncModalOpen}
                                afterSyncCallback={() => onPlanUpdate(false)}
                                onClose={() => {
                                    setSyncModalOpen(false);
                                    setIsPlanSyncLoading(false);
                                }}
                                planID={plan?.PlanID}
                                setMobileAppSyncAt={setMobileAppSyncAt}
                            />
                        </Grid>

                        <Grid item>
                            <SendEmailModal
                                open={emailModalOpen}
                                onClose={() => setEmailModalOpen(false)}
                                planID={plan?.PlanID}
                                setEmailNotificationsSentAt={setEmailNotificationsSentAt}
                            />
                        </Grid>

                        <Grid item>
                            <DeletePlansModal
                                open={deletePlansModalOpen}
                                onClose={() => setDeletePlansModalOpen(false)}
                                planID={plan?.PlanID}
                                onDeletePlan={() => handleDeletePlan()}
                            />
                        </Grid>

                        <Grid item>
                            <UnsyncedChangesWarningModal
                                open={unsyncedChangesWarningModalOpen}
                                onClose={() => {
                                    setUnsyncedChangesWarningModalOpen(false);
                                }}
                                onMove={() => {
                                    setUnsyncedChangesWarningModalOpen(false);
                                    if (modalNavigation === '/planner') {
                                        setDesignStarted(false);
                                        setPlan(DefaultPlan);
                                        setSelectedShifts([]);
                                        setSelectedOperationUnits([]);
                                        setFrom(fromDate);
                                        setTo(toDate);
                                        onReset();
                                    }
                                    if (modalNavigation === '/reverse_copy') {
                                        fetchReverseCopy();
                                        return;
                                    }
                                    navigate(modalNavigation);
                                }}
                            />
                        </Grid>

                        <Grid item>
                            <TravelDistanceWarningModal open={travelDistanceWarningModalOpen} onClose={() => setTravelDistanceWarningModalOpen(false)} />
                        </Grid>

                        <Grid item>
                            <RoundedFormButton
                                disabled={isCreatePlanLoading}
                                title={getButtonText()}
                                icon={getButtonIcon()}
                                onClick={() => {
                                    if (designStarted) {
                                        if (hasUnsyncedData) {
                                            setModalNavigation('/planner');
                                            setUnsyncedChangesWarningModalOpen(true);
                                            return;
                                        }
                                        setWorkplace(DefaultWorkplace);
                                        setPlanNameSuffix('');
                                        setDesignStarted(false);
                                        setPlan(DefaultPlan);
                                        setSelectedShifts([]);
                                        setRequirementDates([]);
                                        setSelectedOperationUnits([]);
                                        setFrom(fromDate);
                                        setTo(toDate);
                                        setDirection('FROM_HOME');

                                        onReset();
                                        navigate('/planner');
                                        return;
                                    }
                                    if (copyMode) {
                                        fetchCopyPlan();
                                    } else {
                                        createRequirement();
                                    }
                                }}
                            />
                        </Grid>
                        {designStarted && (
                            <Grid item>
                                <RoundedFormButton
                                    disabled={isReverseCopyLoading}
                                    title={isScheduleEditing ? 'Mentés' : 'Menetrend Szerkesztése'}
                                    icon={isReverseCopyLoading ? <CircularProgress size={22} /> : isScheduleEditing ? <SaveIcon /> : <EditIcon />}
                                    onClick={() => {
                                        setIsScheduleEditing(!isScheduleEditing);
                                        if (isScheduleEditing) {
                                            fetchUpdateSchedule(plan.PlanID);
                                        }
                                    }}
                                />
                            </Grid>
                        )}

                        {designStarted && direction === 'FROM_HOME' && (
                            <Grid item>
                                <RoundedFormButton
                                    disabled={isReverseCopyLoading}
                                    title={'Visszaút létrehozás'}
                                    icon={isReverseCopyLoading ? <CircularProgress size={22} /> : <SettingsBackupRestoreIcon />}
                                    onClick={() => {
                                        if (hasUnsyncedData) {
                                            setModalNavigation('/reverse_copy');
                                            setUnsyncedChangesWarningModalOpen(true);
                                            return;
                                        }
                                        fetchReverseCopy();
                                    }}
                                />
                            </Grid>
                        )}

                        {designStarted && direction === 'FROM_HOME' && (
                            <Grid item>
                                <RoundedFormButton
                                    color='info'
                                    disabled={isPlanSyncLoading}
                                    title={'Menetrend szinkronizálás'}
                                    icon={isPlanSyncLoading ? <CircularProgress size={22} /> : <CloudUploadIcon />}
                                    onClick={() => {
                                        if (hasNotCalculatedLineTravelDistance) {
                                            setTravelDistanceWarningModalOpen(true);
                                            return;
                                        }
                                        setSyncModalOpen(true);
                                    }}
                                />
                            </Grid>
                        )}

                        {designStarted && (
                            <Grid item>
                                <RoundedFormButton
                                    color='info'
                                    disabled={isTravelDistanceCalculationLoading}
                                    title={'Távolság újraszámolás'}
                                    icon={isTravelDistanceCalculationLoading ? <CircularProgress size={22} /> : <StraightenIcon />}
                                    onClick={updateTravelDistance}
                                />
                            </Grid>
                        )}

                        {designStarted && (
                            <Grid item>
                                <RoundedFormButton
                                    color='info'
                                    title={'E-mail értesítő küldése'}
                                    icon={<EmailIcon />}
                                    onClick={() => setEmailModalOpen(true)}
                                />
                            </Grid>
                        )}

                        {designStarted && (
                            <Grid item>
                                <RoundedFormButton
                                    disabled={isSyncPassengersLoading}
                                    color='warning'
                                    title={'Utazási adatok frissítése'}
                                    icon={isSyncPassengersLoading ? <CircularProgress size={22} /> : <SyncIcon />}
                                    onClick={() => fetchSyncPassengers()}
                                />
                            </Grid>
                        )}

                        <Grid item>
                            <RoundedFormButton color='error' title={'Hibák'} icon={<PriorityHighIcon />} onClick={() => setModalOpen(true)} />
                        </Grid>

                        {designStarted && (
                            <RoundedFormButton color='info' icon={<TimerIcon />} title={'Időpontozó'} onClick={() => setAutoTimeTableModalOpen(true)} />
                        )}

                        {plan.MobileAppSyncedAt === null && plan.EmailNotificationsSentAt === null && (
                            <Grid item>
                                <RoundedFormButton
                                    color='error'
                                    title={'Menetrend törlés'}
                                    icon={<DeleteIcon />}
                                    onClick={() => setDeletePlansModalOpen(true)}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px', gap: '10px' }}>
                        {mobileAppSyncAt && (
                            <Typography variant='caption' color='textSecondary' style={{ display: 'flex', marginRight: '10px', alignItems: 'end' }}>
                                <Tooltip title='Buszom app szinkronizálás időpontja'>
                                    <CloudUploadIcon fontSize='small' style={{ marginRight: '5px' }} />
                                </Tooltip>
                                {new Date(mobileAppSyncAt).toLocaleString('hu-HU', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                })}
                            </Typography>
                        )}
                        {emailNotificationsSentAt && (
                            <Typography variant='caption' color='textSecondary' style={{ display: 'flex', alignItems: 'end' }}>
                                <Tooltip title='Email küldés időpontja'>
                                    <EmailIcon fontSize='small' style={{ marginRight: '5px' }} />
                                </Tooltip>
                                {new Date(emailNotificationsSentAt).toLocaleString('hu-HU', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                })}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </StyledGrid>
        </Grid>
    );
};

export { RequirementSelectForm };
