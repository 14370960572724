import { UniqueIdentifier } from '@dnd-kit/core';

export type LineStop = {
    ID: number;
    Type: 'stop' | 'site';
    CrmID: number;
    Lat: number;
    Lon: number;
    LineId: number;
    Name: string;
    DepartureHour: number;
    DepartureMinute: number;
    CreatedAt: string;
    Position: number;
    Passengers: LineStopPassenger[];
};

export type LineStopPassenger = {
    ID: number;
    LineID: number;
    RequirementID: number;
    LineStopID: number;
    RequirementStopID: number;
    RequirementStopPassengerID: number;
    Name: string;
    StopCrmID: number;
    WorkerID: number;
    Shift: string;
    OperationUnit: string;
    SiteID: number;
    SiteAddress: string;
    SiteName: string;
    SiteLatitude: number;
    SiteLongitude: number;
}

export interface SortableLineStop extends LineStop {
    id: UniqueIdentifier;
}

export type Line = {
    ID: number;
    Capacity: number;
    CreatedAt: string;
    Name: string;
    Stops: LineStop[];
    RequirementID: number;
    ShiftArrivalAt: string;
    ShiftDepartureAt: string;
    TravelDistance: number;
    RoadFeeM2: number;
    RoadFeeM3J2: number;
    RoadFeeM3J3: number;
    UpdatedAt: string;
};

export type LinesResposne = {
    Lines: Line[];
};

export type BusType = {
    value: string;
    title: string;
    shortTitle: string;
    capacity: number;
};

export const BusTypes: BusType[] = [
    {
        value: 'microbus',
        title: 'mikrobusz',
        shortTitle: 'mikro',
        capacity: 8,
    },
    {
        value: 'minibus',
        title: 'minibusz',
        shortTitle: 'mini',
        capacity: 21,
    },
    {
        value: 'midibus',
        title: 'midibusz',
        shortTitle: 'midi',
        capacity: 39,
    },
    {
        value: 'largebus',
        title: 'nagy busz',
        shortTitle: 'nagy',
        capacity: 49,
    },
];

export type DailyPassengers = {
    [key: number]: number;
};

export const LineMarkerColors = [
    '#ff0000',
    '#ffa500',
    '#818100',
    '#008000',
    '#0000ff',
    '#4b0082',
    '#ee82ee',
    '#999999',
    '#fd5b78',
    '#ff9966',
    '#ffcc33',
    '#a3cc00',
    '#aaf0d1',
    '#ff6eff',
    '#ff00cc',
    '#111111',
];

export type LineStopItem = {
    LineID: number;
    Name: string;
    Lat: number;
    Lon: number;
    RequirementStopID?: number;
};
